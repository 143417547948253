<template>
	<div class="navigator">
		<span class="navigator__1">Expert</span><span> /</span
		><span>Доставка и установка</span>
	</div>
	<div class="img-1-cont">
		<div class="img-1-cont__cont-text">
			<h2 class="img-1-cont__h2">
				Доставка и установка входных дверей Expert осуществяется в
				Мосвке и МО
			</h2>
			<div class="img-1-cont__sub-h2">
				Доставку в другие регионы вы можете согласовать с нашим
				менеджером.
			</div>
			<div class="img-1-cont__btn">
				<div>Оставить заявку</div>
				<img
					class="img-1-cont__arrow-img"
					src="images/arrow-9.svg"
					alt=""
				/><img
					class="img-1-cont__circle-img"
					src="images/btn-circle-img.svg"
					alt=""
				/>
			</div>
		</div>
		<img class="img-1" src="images/img-1.jpg" alt="" />
	</div>
	<div class="h2">
		В стоимость продукции от завода "Expert" входит доставка и установка до
		вашего места (не далее 30 км от МКАД).
	</div>
	<div class="sub-h2">
		Доставка осуществляется на специализированном транспорте, водителями,
		прошедшими специальную подготовку.
	</div>
	<div class="p">
		Если место установки расположено далее 30-ти км от МКАД, то стоимость
		доставки расчитывается по специальному тарифу.
	</div>
	<button class="price">1км / 25₽</button
	><a class="sub-price"
		>Бесплатная доставка распространяется при установки входных дверей!</a
	>
</template>
